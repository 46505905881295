div.sqBox {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 4px;
  background: #ffffff;
  border: 1px solid #00649c;
  border-radius: 4px;
  height: 20px;
  min-width: 20px;
  max-width: fit-content;
  float: left;
}

.dark div.sqBox {
  background: #1a2734;
}

div.sqBox label {
  display: flex;
  float: left;
  width: 100%;
  font-size: small;
  text-align: center;
  justify-content: center;
  justify-items: center;
}

div.sqBox label span {
  border-radius: 2px;
  height: 12px;
  width: 12px;
  padding: 5px !important;
  text-align: center;
  display: block;
  cursor: pointer;
}

div.sqBox label input {
  position: absolute;
  top: -20px;
}

div.sqBox input:checked + span {
  border-color: #00649c;
  background-color: #00649c;
  border-radius: 2px;
  cursor: pointer;
}

.dark div.sqBox input:checked + span {
  border-color: #42bbff;
  background-color: #42bbff;
}

div.sqBox > label > input:checked {
  background-color: #00649c;
  border-radius: 2px;
  cursor: pointer;
  border: 1px solid #d5e2ea;
}

.dark div.sqBox > label > input:checked {
  background-color: #42bbff;
}
