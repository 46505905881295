.date-picker-component-popper .react-datepicker {
  @apply border-gray-200;
}

.date-picker-component-popper .react-datepicker {
  @apply w-full;
}

.date-picker-component-popper .react-datepicker__month-container {
  @apply w-full;
}
.date-picker-component-popper .react-datepicker__header {
  @apply bg-white border-b-0 !important;
}

.date-picker-component-popper .react-datepicker__triangle::after {
  @apply hidden !important;
}

.date-picker-component-popper .react-datepicker__triangle::before {
  @apply hidden !important;
}

.date-picker-component-popper .react-datepicker__current-month {
  @apply text-primaryBlue text-xs;
}
.date-picker-component-popper .react-datepicker__day-name {
  @apply text-silver font-light;
}

.date-picker-component-popper .react-datepicker__day {
  @apply text-darkGray;
}
.date-picker-component-popper .react-datepicker__day--keyboard-selected {
  @apply text-white !important;
}
.date-picker-component-popper .react-datepicker__day--selected {
  @apply text-white !important;
}
