.table-extended-draggable {
  display: flex !important;
  user-select: none !important;
  padding-right: 6px !important;
  align-items: center !important;
  justify-content: space-between;
}
.table-extended-draggable.isDragging {
   transform: translate(0, 0) !important;
 }
.table-extended-draggable.isDropAnimating {
   transition-duration: 0.001s !important;
 }

.resizer {
  display: inline-block !important;
  background: #d5e2ea !important;
  width: 1px !important;
  padding-left: 1px !important;
  padding-right: 1px !important;

  height: 18px !important;
  position: absolute !important;
  right: 2px !important;

  transform: translateX(50%) !important;
  z-index: 1 !important;

  /* prevents from scrolling while dragging on touch devices */
  touch-action: none !important;
}
.resizer.isResizing {
   background: #00649c !important;
 }
.resizer.resizingHidden {
   display: none !important;
}