.top-bar-triangle {
  width: 0;
  height: 0;
  top: 10px;
  left: -9px;
  margin: 0 auto;
  position: absolute;
  border-right: 5px solid white;
  border-top: 0 solid transparent;
  border-left: 0 solid transparent;
  border-bottom: 0 solid transparent;
  border-top-width: 9px;
  border-left-width: 0px;
  border-right-width: 9px;
  border-bottom-width: 9px;
  background: transparent;
}

.up-arrow {
}
.up-arrow:before {
  content: "";
  display: block;
  position: absolute;
  left: -25px;
  bottom: 3px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right-color: #e5e7eb;
}

/* .up-arrow:after {
  content: "";
  display: block;
  position: absolute;
  left: 2px;
  bottom: 181px;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-right-color: white;
} */
