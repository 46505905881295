@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/Ubuntu/Ubuntu-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/Ubuntu/Ubuntu-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/Ubuntu/Ubuntu-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/Ubuntu/Ubuntu-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}
